import React from "react";
import ReactDOM from "react-dom";
import "./char.css";
import "react-tabs/style/react-tabs.css";
import "jodit/build/jodit.min.css";
import "./tail.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql";
import AuthProvider from "./context/AuthContext";
import { ToastProvider } from "react-toast-notifications";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AppProvider from "./providers/AppProvider";

const PGate = PersistGate as any;
const TProvider = ToastProvider as any;

const Renderer = () => (
  <Provider store={store}>
    <PGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <TProvider autoDismiss placement="bottom-center">
          <AuthProvider>
            <AppProvider value={{ granted: false }}>
              <App />
            </AppProvider>
          </AuthProvider>
        </TProvider>
      </ApolloProvider>
    </PGate>
  </Provider>
);

ReactDOM.render(<Renderer />, document.getElementById("root"));

serviceWorker.unregister();

//https://manage.kekrebrokerapp.com/graphql
